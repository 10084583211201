<template>
  <div id="students-in-school" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <b-table :data="schoolClasses" hoverable>
        <b-table-column field="#" label="#" width="40" numeric v-slot="props">
          {{ schoolClasses.indexOf(props.row) + 1 }}
        </b-table-column>

        <b-table-column label="Class" v-slot="props">
          <router-link
            :to="`/school/${$route.params.school_id}/students/${props.row.id}`"
            >{{ props.row.name }}</router-link
          >
        </b-table-column>

        <b-table-column label="Number in class" v-slot="props">{{
          props.row.female + props.row.male
        }}</b-table-column>

        <b-table-column label="Female" v-slot="props">{{
          props.row.female
        }}</b-table-column>

        <b-table-column label="Male" v-slot="props">{{
          props.row.male
        }}</b-table-column>

        <b-table-column label="Parent's Info" width="120" v-slot="props">
          {{ props.row.registeredParents }} /
          {{ props.row.female + props.row.male }}
        </b-table-column>

        <b-table-column width="40" v-slot="props">
          <div class="buttons is-left">
            <router-link
              :to="`/school/${schoolId}/school_class_students_pdf/${props.row.id}`"
              class="is-small has-text-info"
            >
              <fa-icon icon="file-pdf" size="lg" />
            </router-link>
          </div>
        </b-table-column>
      </b-table>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
  name: 'students',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Students',
      schoolClasses: [],
    }
  },
  methods: {
    export(student) {
      this.$router.push(`/student_info/${student.id}`)
    },
  },
  mounted() {
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'New',
        route: `/school/${this.schoolId}/student/new`,
      },
      {
        name: 'Students PDF',
        route: `/school/${this.schoolId}/students_pdf`,
      },
      { name: 'Search', route: `/school/${this.schoolId}/student_search` },
      // { name: "Excel Export", route: "/student/new" },
      { name: 'Bulk Upload', route: `/school/${this.schoolId}/bulk_upload` },
    ])

    this.$apollo.addSmartQuery('schoolClasses', {
      query: gql`
        query SchoolClasses($schoolId: Int!) {
          schoolClasses(schoolId: $schoolId) {
            id
            name
            female
            male
            registeredParents
          }
        }
      `,
      variables() {
        return { schoolId: this.schoolId }
      },
    })

    this.$apollo.queries.schoolClasses.refetch()
  },
}
</script>
